import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// app/javascript/packs/application.js
import 'bootstrap';

import "./app-globals"
import "./jquery.mask"
import $ from "jquery";
require("easy-autocomplete")
Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).ready(function(){
    $("#loading").hide();
});

$(document).on('submit', 'form', function() {
    $('#loading').show();
});

$(document).on('click', 'a, button', function(event) {
    if (!event.metaKey && !event.ctrlKey &&
        !$(event.target).closest('.btn-close, .nav-link, ' +
            '.navbar-toggler, .dropdown-toggle, #toTop, ' +
            '.skip-loading, .bi-file-earmark-spreadsheet').length) {
        $('#loading').show();
    }
});

// Eventos de Turbolinks
document.addEventListener("turbolinks:request-start", function() {
    $('#loading').show(); // Muestra el loading al iniciar una solicitud de Turbolinks
});

document.addEventListener("turbolinks:load", function() {
    $('#loading').hide(); // Oculta el loading cuando la página se ha cargado completamente
});

document.addEventListener("turbolinks:before-cache", function() {
    $('#loading').hide(); // Asegúrate de ocultar el loading antes de almacenar en caché la página
});

// Manejo de la navegación con el botón atrás/adelante
window.addEventListener('popstate', function() {
    $('#loading').hide(); // Ocultar el loading al usar el botón de atrás o adelante
});

// Fallback en caso de que `load` sea necesario
$(window).on('load', function() {
    $('#loading').hide();
});

$(window).bind("beforeunload",function(event){
    $("#loading").show();
});
