import $ from "jquery";

$(document).ready(function($){
    $(".phone").mask("(9999) 999-999");
    $('.validate').bind("change paste keydown", function(){
        var name = $(this).attr("id");
        var tam = $(this).attr("size");
        validarText(this, name + "_div", name + "_message", 2, tam);
    });
});

document.addEventListener("DOMContentLoaded", function() {
    const footer = document.getElementById("footer");

    function adjustFooter() {
        const bodyHeight = document.body.scrollHeight;
        const windowHeight = window.innerHeight;

        if (bodyHeight <= windowHeight) {
            footer.classList.add("fixed-footer");
        } else {
            footer.classList.remove("fixed-footer");
        }
    }

    window.addEventListener("resize", adjustFooter);
    window.addEventListener("load", adjustFooter);
    adjustFooter();
});


$(document).on('click', '.notification-item', function() {
    let notificationId = $(this).data('notification-id');
    let notificationItem = $(this);
    let notificationValue = parseInt($('#notification-number').html());
    if (notificationValue > 0) {
        $.ajax({
            type: 'POST',
            url: '/notifications/' + notificationId + '/notifications_mark_seeing_in_menu/' + notificationId ,
            dataType: 'json',
            success: function(response) {
                notificationItem.removeClass('bg-info-light');
                $('#notification-number').html(notificationValue - 1)
            },
            error: function(xhr, status, error) {
                console.error('Error al marcar la notificación como leída:', error);
            }
        });
    }
});


window.validarText = function(input,div,p,min,max) {
    var txt = $(input).val();
    if (txt.length < min){
        $('#'+div).addClass('field_with_errors');
        $('#'+p).html('*Este campo es requerido y debe ser mayor a ' + min + ' caracteres');
        $("input[name=commit]").prop( "disabled", true );
    } else if (txt.length > max){
        $('#'+div).addClass('field_with_errors');
        $('#'+p).html('*Este campo debe ser menor a ' + max + ' caracteres');
        $("input[name=commit]").prop( "disabled", true );
    } else {
        if($('#'+div).hasClass('field_with_errors')){
            $('#'+div).removeClass('field_with_errors');
            $('#'+p).html('');
            $("input[name=commit]").prop( "disabled", false );
        }
    }
}